/**
 * SmartGeneva by Mandat International (C) 2020
 * frontend: @MacMladen
 */
// Abstracts
@import "abstracts/customize";
@import "abstracts/functions";
@import "abstracts/mixin";

// from Vendors
@import 'bootstrap/scss/bootstrap';
@import '@fortawesome/fontawesome-free/scss/fontawesome';

// Base
@import "base/base";

// Utilities
//@import "utilities/utilities";

// Components
//@import "components/mmc_cal";

// MUST NOT EXIST!
@import "DIRTY";

/*! SmartGeneva Styles.CSS end */