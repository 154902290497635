// Small devices
@mixin sm {
  @media (min-width: #{$sm}) {
    @content;
  }
}

// Medium devices
@mixin md {
  @media (min-width: #{$md}) {
    @content;
  }
}

// Large devices
@mixin lg {
  @media (min-width: #{$lg}) {
    @content;
  }
}

// Custom devices
@mixin rwd($screen) {
  @media (min-width: $screen) {
    @content;
  }
}


/*
// From Bootstrap 3
$sm: 768px;
$md: 992px;
$lg: 1200px;

  USAGE
  =========================
.container {
    padding: 0 15px;
// 768px window width and more
    @include sm {
        padding: 0 20px;
    }
// 1200px window width and more
    @include lg {
        margin-left: auto;
        margin-right: auto;
        max-width: 1100px;
    }
// 1400px window width and more
    @include rwd(1400) {
        margin-bottom: 20px;
        margin-top: 20px;
    }
}
 */
